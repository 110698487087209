import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import config from '../../config'

const { twitter, fbAppID } = config

export default class SEO extends Component {
  getSEODataForPost() {
    const { post } = this.props
    const { frontmatter } = post

    const { title } = frontmatter
    const description = frontmatter.description || post.excerpt
    const image = frontmatter.cover ? config.url + frontmatter.cover : ''
    const postURL = config.baseURL + post.fields.path

    return {
      title,
      description,
      image,
      url: postURL,
      schemaOrgJSONLD: [
        {
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: config.baseURL,
          name: title,
          alternateName: config.titleAlt ? config.titleAlt : '',
        },
        {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': postURL,
                name: title,
                image,
              },
            },
          ],
        },
        {
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          url: postURL,
          name: title,
          alternateName: config.titleAlt ? config.titleAlt : '',
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: image,
          },
          description,
        },
      ],
    }
  }

  getSEOData() {
    const { title, description, logo, baseURL } = config

    return {
      title,
      description: description || title,
      image: logo ? baseURL + logo : '',
      url: baseURL,
      schemaOrgJSONLD: [
        {
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: baseURL,
          name: title,
          alternateName: config.titleAlt ? config.titleAlt : '',
        },
      ],
    }
  }

  render() {
    const { post } = this.props
    const { title, description, image, url, schemaOrgJSONLD } = post
      ? this.getSEODataForPost()
      : this.getSEOData()

    return (
      <Helmet>
        {/* General tags */}
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {post ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {image ? <meta property="og:image" content={image} /> : null}
        {fbAppID ? <meta property="fb:app_id" content={fbAppID} /> : null}

        {/* Twitter Card tags */}
        {twitter ? (
          <meta name="twitter:card" content="summary_large_image" />
        ) : null}
        {twitter ? <meta name="twitter:creator" content={twitter} /> : null}
        {twitter ? <meta name="twitter:title" content={title} /> : null}
        {twitter ? (
          <meta name="twitter:description" content={description} />
        ) : null}
        {twitter && image ? (
          <meta name="twitter:image" content={image} />
        ) : null}
      </Helmet>
    )
  }
}

SEO.propTypes = {
  // The node object of a blog post.
  // If set, the SEO data will serve for a blog post.
  post: PropTypes.object,
}

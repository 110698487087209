import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Excerpt from '../components/excerpt'
import SEO from '../components/seo'
import Layout from '../components/layout'

class HomePage extends React.Component {
  render() {
    const {
      location,
      data: {
        site: {
          siteMetadata: { title },
        },
        allMarkdownRemark: { edges: posts },
      },
    } = this.props

    return (
      <Layout location={location}>
        <Helmet title={title} />
        <SEO />
        {posts
          .filter(post => post.node.path !== '/404/')
          .map(({ node: { excerpt, frontmatter, fields: { postPath } } }) => (
            <Excerpt
              key={postPath}
              path={postPath}
              title={frontmatter.title}
              date={frontmatter.date}
              excerpt={excerpt}
            />
          ))}
      </Layout>
    )
  }
}

HomePage.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object,
}

export default HomePage

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            postPath
          }
        }
      }
    }
  }
`
